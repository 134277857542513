module.exports = {
  env: 'production',
  apiUrl: '',
  authConfig: {},
  services: {
    authApiBaseUrl: "https://auth.api.yodaforex.cloud",
    licenseApiBaseUrl: "https://license.api.yodaforex.cloud",
    webcoApiBaseUrl: "https://web-connect.api.yodaforex.fr",
  }
}
